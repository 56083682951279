
import { Component, Vue } from '@/lib/decorator';
import AnalyticsEntityModule from '@/store/analytics/entity';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import FilterInstant from '@/components/FilterInstant.vue';
import TableList from '@/components/data/TableList.vue';
import EChart from '@/components/EChart.vue';
import AnalyticsByEmployeesEntityModule from '@/store/analytics/byEmployees';
import UserModule from '@/store/user';
import RolesChartOptions, { init as initRolesOption } from './RolesChartOptions';
import LmkChartOptions, { init as initLmkOption } from './LmkChartOptions';
import SelfEmployedChartOptions, { init as initSelfEmployedOption } from './SelfEmployedChartOptions';
import SplitsChartOptions, { init as initSplitOption } from './SplitsChartOptions';
import PayoutsChartOptions, { init as initPayoutsOption } from './PayoutsChartOptions';
import StatusesChartOptions from './StatusesChartOptions';
import EmployeesModule from '@/store/employees';
import { chartDataInterface, chartOptionsInterface } from '@/views/analytics/byEmployees/interfaces';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { Checkbox } from '@/interfaces/filter.interface';
import { GuiCheckbox, GuiDropdown } from '@library/gigant_ui';
import { getRegionsGeoAll } from '@/api/region';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { DropdownItem } from '@library/gigant_ui/dist/types/interfaces/dropdownItem';

const chartColors = [
  '#74D695', //chartColorGreen
  '#AD90D3', //chartColorPurple
  '#E27F7F', //chartColorRed
  '#515184', //chartColorDarkPurple
  '#5B93CC', //chartColorBlue
  '#5870A0', //chartColorDarkBlue
  '#E9C56A', //chartColorYellow
  '#74D695', //chartColorGreen
  '#AD90D3', //chartColorPurple
  '#E27F7F', //chartColorRed
  '#515184', //chartColorDarkPurple
  '#5B93CC', //chartColorBlue
  '#5870A0', //chartColorDarkBlue
  '#E9C56A', //chartColorYellow
];

@Component({
  components: {
    GuiCheckbox,
    GuiDropdown,
    TitleReturn,
    TabsNav,
    FilterInstant,
    TableList,
    EChart,
  },
})
export default class AnalyticsByEmployees extends Vue {
  filterRegion = '';
  regionItems: DropdownItem[] = [];
  isNotTest = true;

  rolesChartOptions = RolesChartOptions;
  initRolesOption = initRolesOption;

  lmkChartOptions = LmkChartOptions;
  initLmkOption = initLmkOption;

  selfEmployedChartOptions = SelfEmployedChartOptions;
  initSelfEmployedOption = initSelfEmployedOption;

  splitsChartOptions = SplitsChartOptions;
  initSplitOption = initSplitOption;

  payoutDataChartOptions = PayoutsChartOptions;
  initPayoutDataOption = initPayoutsOption;

  statusesChartOptions = StatusesChartOptions;

  cols = [{ value: 'percent' }, { value: 'name', width: '100%' }, { value: 'cnt' }];

  get tabsNav(): TabsNavInterface {
    return AnalyticsEntityModule.tabsNav;
  }

  get currentTab(): string {
    return AnalyticsEntityModule.tabsNav.byEmployees?.id;
  }

  get rolesTotal(): number {
    return AnalyticsByEmployeesEntityModule.rolesTotal;
  }

  get roles(): { color: string; class: string }[] {
    return (AnalyticsByEmployeesEntityModule.roles as { cnt: number }[])
      .sort((a: { cnt: number }, b: { cnt: number }) => {
        if (typeof a.cnt !== 'undefined' && typeof b.cnt !== 'undefined') {
          return b.cnt - a.cnt;
        }

        return 0;
      })
      .map((item: Record<string, number>, index: number) => ({
        ...item,
        color: chartColors[index],
        class: index === 0 ? 'cp-table-list-row--group-1 cp-table-list-row--first' : '',
      }));
  }

  get rolesChart(): chartOptionsInterface {
    return this.mergeChartWithData({
      data: this.roles,
      template: this.rolesChartOptions,
    });
  }

  get lmkTotal(): number {
    return AnalyticsByEmployeesEntityModule.lmkTotal;
  }

  get lmk(): { color: string; class: string }[] {
    return (AnalyticsByEmployeesEntityModule.lmk as { cnt: number }[])
      .sort((a: { cnt: number }, b: { cnt: number }) => {
        if (typeof a.cnt !== 'undefined' && typeof b.cnt !== 'undefined') {
          return b.cnt - a.cnt;
        }

        return 0;
      })
      .map((item: Record<string, number>, index: number) => ({
        ...item,
        color: chartColors[index],
        class: index === 0 ? 'cp-table-list-row--group-1 cp-table-list-row--first' : '',
      }));
  }

  get lmkChart(): chartOptionsInterface {
    return this.mergeChartWithData({
      data: this.lmk,
      template: this.lmkChartOptions,
    });
  }

  get selfEmployedTotal(): number {
    return AnalyticsByEmployeesEntityModule.selfEmployedTotal;
  }

  get selfEmployed(): { color: string; class: string }[] {
    return (AnalyticsByEmployeesEntityModule.selfEmployed as { cnt: number }[])
      .sort((a: { cnt: number }, b: { cnt: number }) => {
        if (typeof a.cnt !== 'undefined' && typeof b.cnt !== 'undefined') {
          return b.cnt - a.cnt;
        }

        return 0;
      })
      .map((item: Record<string, number>, index: number) => ({
        ...item,
        color: chartColors[index],
        class: index === 0 ? 'cp-table-list-row--group-1 cp-table-list-row--first' : '',
      }));
  }

  get selfEmployedChart(): chartOptionsInterface {
    return this.mergeChartWithData({
      data: this.selfEmployed,
      template: this.selfEmployedChartOptions,
    });
  }

  get splitsTotal(): number {
    return AnalyticsByEmployeesEntityModule.splitsTotal;
  }

  get splits(): { color: string; class: string }[] {
    return (AnalyticsByEmployeesEntityModule.splits.filter((item: Record<string, number>) => {
      return item.cnt && item.cnt > 0;
    }) as { cnt: number }[])
      .sort((a: { cnt: number }, b: { cnt: number }) => {
        if (typeof a.cnt !== 'undefined' && typeof b.cnt !== 'undefined') {
          return b.cnt - a.cnt;
        }

        return 0;
      })
      .map((item: Record<string, number>, index: number) => ({
        ...item,
        color: chartColors[index],
        class: index === 0 ? 'cp-table-list-row--group-1 cp-table-list-row--first' : '',
      }));
  }

  get splitsChart(): chartOptionsInterface {
    return this.mergeChartWithData({
      data: this.splits,
      template: this.splitsChartOptions,
    });
  }

  get payoutDataTotal(): number {
    return AnalyticsByEmployeesEntityModule.payoutDataTotal;
  }

  get payoutData(): { color: string; class: string }[] {
    return (AnalyticsByEmployeesEntityModule.payoutData as { cnt: number }[])
      .sort((a: { cnt: number }, b: { cnt: number }) => {
        if (typeof a.cnt !== 'undefined' && typeof b.cnt !== 'undefined') {
          return b.cnt - a.cnt;
        }

        return 0;
      })
      .map((item: Record<string, number>, index: number) => ({
        ...item,
        color: chartColors[index],
        class: index === 0 ? 'cp-table-list-row--group-1 cp-table-list-row--first' : '',
      }));
  }

  get payoutDataChart(): chartOptionsInterface {
    return this.mergeChartWithData({
      data: this.payoutData,
      template: this.payoutDataChartOptions,
    });
  }

  get statuses(): Record<string, string | number>[] {
    return (AnalyticsByEmployeesEntityModule.statuses.map((item: Record<string, number>, index: number) => ({
      ...item,
      color: chartColors[index],
    })) as Record<string, string | number>[]).sort(
      (a: Record<string, string | number>, b: Record<string, string | number>) => {
        if (typeof a.cnt !== 'undefined' && typeof b.cnt !== 'undefined') {
          return (a as { cnt: number }).cnt - (b as { cnt: number }).cnt;
        }

        return 0;
      }
    );
  }

  get statusesChart(): chartOptionsInterface {
    return this.mergeChartWithData({
      data: this.statuses,
      template: this.statusesChartOptions,
    });
  }

  mergeChartWithData(param: { data: chartDataInterface[]; template: chartOptionsInterface }): chartOptionsInterface {
    let yAxisData: string[] = [];
    const chartData = param.data
      .filter(
        (el: chartDataInterface) => (el as Record<string, number>).percent || (el as Record<string, number>).percent === 0
      )
      .map((el: chartDataInterface, index: number) => {
        yAxisData.push((el as Record<string, string>).name as string);

        return {
          id: (el as Record<string, string>).type,
          value: (el as Record<string, string>).percent,
          name: (el as Record<string, string>).name,
          count: (el as Record<string, string>).cnt,
          label: {
            color: chartColors[index],
          },
          itemStyle: {
            color: chartColors[index],
          },
        };
      });

    param.template.series.forEach((el: Record<string, chartDataInterface>) => {
      if (el.type === 'bar') {
        param.template.yAxis.data = yAxisData;

        /**
         * Это загадка, ответ на которую я не нашел. Иногда отрисовывается только один бар.
         * Поэтому я добавляю фиктивный элемент, чтобы все остальные отрисовались
         */
        if (chartData.length > 1) {
          chartData.push({
            id: 'just_empty_item',
            value: '0',
            count: '0',
            name: 'just_empty_item',
            label: {
              color: chartColors[0],
            },
            itemStyle: {
              color: chartColors[0],
            },
          });
        }
      }

      el.data = chartData;
    });

    return param.template;
  }

  prepareFilters(): string {
    let filter = this.isNotTest ? 'isNotTest=1' : '';
    if (this.filterRegion) {
      filter += filter.length ? '&' : '';
      filter += 'geoRegionId=' + this.filterRegion;
    }

    return filter;
  }

  async updateList(): Promise<void> {
    await AnalyticsByEmployeesEntityModule.getStatistics(this.prepareFilters());
  }

  async onRoleClicked(type: string, name: string): Promise<void> {
    let employeesRouteData = this.$router.resolve({ name: 'employees' });

    switch (type) {
      case 'ROLE_EMPLOYEE':
      case 'ROLE_DEACTIVATED':
      case 'ROLE_AWAITING': {
        await EmployeesModule.filterModule.resetFilter();

        await EmployeesModule.updateRole({
          key: 'role',
          value: {
            id: type,
            value: name,
          },
        });

        if (this.filterRegion) {
          this.regionItems.map(async (item: DropdownItem) => {
            if (item.id === this.filterRegion) {
              await EmployeesModule.updateRegion({
                key: 'region',
                value: {
                  id: item.id,
                  value: item.label,
                },
              });
            }
          });
        }

        await EmployeesModule.filterModule.updateFilter();

        window.open(employeesRouteData.href, '_blank');
        break;
      }
    }
  }

  get token(): string | null {
    return UserModule.token;
  }

  async getRegionItems(): Promise<void> {
    try {
      const regionsAll = await getRegionsGeoAll();

      this.regionItems = (Object.values(regionsAll) as { id: number; name: string }[]).map(
        (region: { id: number; name: string }) => {
          return {
            id: region.id.toString(),
            value: region.id.toString(),
            label: region.name,
            icon: '',
            disabled: false,
          };
        }
      );
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      this.regionItems = [];
    }
  }

  async mounted(): Promise<void> {
    await this.getRegionItems();

    AnalyticsEntityModule.initTabsItems();
    await this.updateList();
  }

  async onStatusesChartClicked(event: { data: { id: number; name: string } }): Promise<void> {
    let employeesRouteData = this.$router.resolve({ name: 'employees' });

    await EmployeesModule.filterModule.resetFilter();

    if (this.filterRegion) {
      this.regionItems.map(async (item: DropdownItem) => {
        if (item.id === this.filterRegion) {
          await EmployeesModule.updateRegion({
            key: 'region',
            value: {
              id: item.id,
              value: item.label,
            },
          });
        }
      });
    }
    await EmployeesModule.updateRole({
      key: 'role',
      value: {
        id: 'ROLE_EMPLOYEE',
        value: 'Активен',
      },
    });
    await EmployeesModule.filterModule.updateMultiSelectList({
      key: 'status',
      list: [
        {
          id: event.data.id,
          value: event.data.name,
          checked: true,
        } as Checkbox,
      ],
    });
    await EmployeesModule.filterModule.updateMultiSelectBeforeInit({
      key: 'status',
      value: {
        id: event.data.id,
        value: event.data.name,
      },
    } as never);

    await EmployeesModule.filterModule.updateFilter();

    setTimeout(() => {
      window.open(employeesRouteData.href, '_blank');
    });
  }
}
