import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import PageBaseModule from '@/store/page';
import { getStatisticsByEmployee } from '@/api/analytics';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';

export interface StatisticsByEmployeeModel {
  lmk: Record<string, number>[];
  payoutData: Record<string, number>[];
  roles: Record<string, number>[];
  selfEmployed: Record<string, number>[];
  splits: Record<string, number>[];
  statuses: Record<string, number>[];
}

export const MODULE_NAME = 'analyticsByEmployees';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class AnalyticsByEmployeesModule extends PageBaseModule {
  rolesTotal = 0;
  roles: Record<string, number>[] = [];
  lmkTotal = 0;
  lmk: Record<string, number>[] = [];
  selfEmployedTotal = 0;
  selfEmployed: Record<string, number>[] = [];
  splitsTotal = 0;
  splits: Record<string, number>[] = [];
  payoutDataTotal = 0;
  payoutData: Record<string, number>[] = [];
  statuses: Record<string, number>[] = [];

  constructor(module: AnalyticsByEmployeesModule) {
    super(module);
  }

  @Mutation
  SET_STATISTICS(params: StatisticsByEmployeeModel) {
    this.rolesTotal = 0;
    this.lmkTotal = 0;
    this.selfEmployedTotal = 0;
    this.splitsTotal = 0;
    this.payoutDataTotal = 0;

    this.roles = params.roles;
    if (params.roles) {
      params.roles.map((item: Record<string, number>) => {
        this.rolesTotal += item.cnt;
      });
    }

    this.lmk = params.lmk;
    if (params.lmk) {
      params.lmk.map((item: Record<string, number>) => {
        this.lmkTotal += item.cnt;
      });
    }

    this.selfEmployed = params.selfEmployed;
    if (params.selfEmployed) {
      params.selfEmployed.map((item: Record<string, number>) => {
        this.selfEmployedTotal += item.cnt;
      });
    }

    this.splits = params.splits;
    if (params.splits) {
      params.splits.map((item: Record<string, number>) => {
        this.splitsTotal += item.cnt;
      });
    }

    this.payoutData = params.payoutData;
    if (params.payoutData) {
      params.payoutData.map((item: Record<string, number>) => {
        this.payoutDataTotal += item.cnt;
      });
    }

    this.statuses = params.statuses;
  }

  @Action({ rawError: true })
  async getStatistics(filter: string) {
    try {
      const result = await getStatisticsByEmployee(filter);

      setTimeout(() => {
        this.SET_STATISTICS(result);
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }
}

export default getModule(AnalyticsByEmployeesModule);
