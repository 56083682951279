export default {
  xAxis: {
    type: 'value',
    scale: true,
    show: false,
    splitLine: {
      show: false,
    },
    splitArea: {
      show: false,
    },
    triggerEvent: true,
  },
  yAxis: {
    type: 'category',
    axisLabel: {
      show: true,
      fontSize: 14,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
    splitArea: {
      show: false,
    },
    data: [],
  },
  series: [
    {
      type: 'bar',
      itemStyle: {
        borderRadius: 8,
        borderColor: '#fff',
        borderWidth: 4,
      },
      label: {
        show: true,
        precision: 1,
        position: 'right',
        formatter: (params: { data: { count: number }; value: number }): string => {
          return `{a|${params.data.count}} • ${Math.round(params.value)}%`;
        },
        rich: {
          a: {
            color: '#333333',
          },
        },
      },
      barMaxWidth: 30,
      data: [],
    },
  ],
  grid: { containLabel: true },
};
