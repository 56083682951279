export default {
  series: [
    {
      type: 'pie',
      radius: [52, 114],
      center: ['50%', '50%'],
      avoidLabelOverlap: false,
      hoverOffset: 2,
      itemStyle: {
        borderRadius: 8,
        borderColor: '#fff',
        borderWidth: 4,
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          formatter: (params: { percent: number }): string => {
            return `${Math.round(params.percent)}%`;
          },
          fontSize: '32',
          fontWeight: 'bold',
        },
      },
      data: [],
    },
  ],
  yAxis: {
    show: false,
  },
  xAxis: {
    show: false,
  },
};

export const init = {
  width: 228,
  height: 228,
};
