export default {
  series: [
    {
      type: 'pie',
      radius: [15, 60],
      center: ['50%', '50%'],
      avoidLabelOverlap: false,
      hoverOffset: 2,
      itemStyle: {
        borderRadius: 8,
        borderColor: '#fff',
        borderWidth: 4,
      },
      label: {
        show: false,
      },
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
  yAxis: {
    show: false,
  },
  xAxis: {
    show: false,
  },
};

export const init = {
  width: 120,
  height: 120,
};
